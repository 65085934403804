import styled from "styled-components"

import { transition } from "./../../common/mixins"
import { media, customMedia } from "./../../common/MediaQueries"

const NumberBoxMediaStyled = styled.div`
  ${transition()};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.brand};

  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  z-index: 2;
  svg {
    ${transition()};
    height: 100%;
    fill: ${({ theme }) => theme.colors.brand};

    @media ${customMedia.maxTablet} {
      width: auto;
    }
    @media ${media.tablet} {
      height: auto;
      width: 100%;
    }
  }
  @media ${media.tablet} {
    width: 100%;
  }
`

const NumberBoxContentStyled = styled.div`
  ${transition()};
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  flex: 1;
  max-width: 330px;
  opacity: 0;
  z-index: 2;
`

const NumberBoxTitleStyled = styled.h3`
  font-family: ${({ theme }) => theme.fontFamilies.publico};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  line-height: 1.2;
  letter-spacing: -0.33px;
  margin-bottom: 30px;
  user-select: none;
  word-break: break-word;
`

const NumberBoxInfoStyled = styled.div`
  font-size: 16px;
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 30px;
  word-break: break-word;
  strong {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`

const NumberBoxInfoDescStyled = styled.p`
  user-select: none;
`

const NumberBoxInfoScoreStyled = styled.p`
  user-select: none;
`

const NumberBoxActionStyled = styled.div`
  user-select: none;
`

const NumberBoxStyled = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;

  &:hover {
    ${NumberBoxMediaStyled} {
      svg {
        opacity: 0.3;
        color: ${({ theme }) => theme.colors.black};
      }
    }
    ${NumberBoxContentStyled} {
      opacity: 1;
    }
  }
`

export {
  NumberBoxStyled,
  NumberBoxMediaStyled,
  NumberBoxContentStyled,
  NumberBoxTitleStyled,
  NumberBoxInfoStyled,
  NumberBoxInfoDescStyled,
  NumberBoxInfoScoreStyled,
  NumberBoxActionStyled,
}
