import React from "react"

import {
  BrandsGridContainerStyled,
  BrandsGridStyled,
  BrandsGridItemStyled,
} from "./styles"
import NumberBox from "./../NumberBox"

interface IProps {
  data: object
  images: []
}

const BrandsGrid: React.FC<IProps> = ({ data, images }) => {
  const items = data.brands.nodes

  images.map((el, i) => {
    el.data = items[i]
  })

  return (
    <BrandsGridContainerStyled>
      <BrandsGridStyled>
        {images.map(image => {
          return (
            <BrandsGridItemStyled>
              <NumberBox data={image.data} {...{ image }} />
            </BrandsGridItemStyled>
          )
        })}
      </BrandsGridStyled>
    </BrandsGridContainerStyled>
  )
}

export default BrandsGrid
