import React from "react"

import {
  NumberBoxStyled,
  NumberBoxMediaStyled,
  NumberBoxContentStyled,
  NumberBoxTitleStyled,
  NumberBoxInfoStyled,
  NumberBoxInfoDescStyled,
  NumberBoxInfoScoreStyled,
  NumberBoxActionStyled,
} from "./styles"
import IconButton from "./../IconButton"

interface IProps {
  data?: object
  image?: React.ReactNode
}

const NumberBox: React.FC<IProps> = ({ data, image }) => {
  return (
    <NumberBoxStyled>
      <NumberBoxMediaStyled>{image}</NumberBoxMediaStyled>
      <NumberBoxContentStyled>
        <NumberBoxTitleStyled>{data && data.data.Name}</NumberBoxTitleStyled>
        <NumberBoxInfoStyled>
          <NumberBoxInfoDescStyled>
            {data && data.data.Sector}
          </NumberBoxInfoDescStyled>
          {data && data.data.Score ? (
            <NumberBoxInfoScoreStyled>
              Score: <strong>{data.data.Score}</strong>
            </NumberBoxInfoScoreStyled>
          ) : (
            ""
          )}
        </NumberBoxInfoStyled>
        {data && data.data.Slug ? (
          <NumberBoxActionStyled>
            <IconButton
              href={`/${data.data.Slug}`}
              text="Read more"
              isInternalLink
            />
          </NumberBoxActionStyled>
        ) : (
          ""
        )}
      </NumberBoxContentStyled>
    </NumberBoxStyled>
  )
}

export default NumberBox
