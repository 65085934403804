import styled from "styled-components"

import { media } from "./../../common/MediaQueries"
import { getGenerateNthChildColor } from "./../../common/mixins"
import {
  NumberBoxStyled,
  NumberBoxContentStyled,
  NumberBoxTitleStyled,
  NumberBoxInfoStyled,
  NumberBoxActionStyled,
  NumberBoxMediaStyled,
} from "./../NumberBox/styles"

const BrandsGridContainerStyled = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`

const BrandsGridStyled = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  grid-gap: 2px;
  overflow: hidden;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(27, minmax(221px, 1fr));

  @media ${media.phone} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(19, minmax(229px, 1fr));
  }

  @media ${media.tablet} {
    overflow: auto;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(12, minmax(226px, 1fr));
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    flex-wrap: wrap;
  }
`

const BrandsGridItemStyled = styled.li`
  display: flex;
  background-color: ${({ theme }) => theme.colors.black};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    border: 1px solid ${({ theme }) => theme.colors.white};
    &:nth-child(-n + 3) {
      width: 33.3%;
    }
    &:nth-child(n + 4) {
      width: 25%;
    }
    &:nth-child(n + 48) {
      width: 33.3%;
    }
  }

  &:nth-child(-n + 3) {
    ${NumberBoxMediaStyled} {
      padding: 22px 32px;
    }
    ${NumberBoxMediaStyled} {
      justify-content: flex-end;
    }
  }

  &:nth-child(1) {
    grid-column: 1 / 3;
    ${NumberBoxStyled} {
      &:hover {
        ${NumberBoxMediaStyled} {
          background-color: ${({ theme }) => theme.colors.green};
        }
      }
    }
  }
  &:nth-child(2) {
    grid-column: 1 / 3;
    ${NumberBoxStyled} {
      &:hover {
        ${NumberBoxMediaStyled} {
          background-color: ${({ theme }) => theme.colors.egg};
        }
      }
    }
  }
  &:nth-child(3) {
    grid-column: 1 / 3;
    ${NumberBoxStyled} {
      &:hover {
        ${NumberBoxMediaStyled} {
          background-color: ${({ theme }) => theme.colors.helio};
        }
      }
    }
  }
  &:nth-child(4) {
    ${NumberBoxStyled} {
      &:hover {
        ${NumberBoxMediaStyled} {
          background-color: ${({ theme }) => theme.colors.blue};
        }
      }
    }
  }
  &:nth-child(n + 4) {
    grid-column: span 1;
    ${NumberBoxMediaStyled} {
      svg {
        max-width: 132px;
        height: 120px;
      }
    }
  }

  ${getGenerateNthChildColor(4, 46, NumberBoxStyled, NumberBoxMediaStyled)};

  &:nth-child(n + 50) {
    grid-column: span 1;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 402px;
      background: ${({ theme }) => theme.colors.black};
      top: 0;
      right: -404px;
    }
  }

  @media ${media.phone} {
    &:nth-child(-n + 3) {
      ${NumberBoxMediaStyled} {
        padding: 26px 40px;
      }
    }
    &:nth-child(1) {
      grid-column: 1 / 4;
    }
    &:nth-child(2) {
      grid-column: 1 / 4;
    }
    &:nth-child(3) {
      grid-column: 1 / 4;
    }
    &:nth-child(n + 4) {
      grid-column: span 1;
      ${NumberBoxMediaStyled} {
        svg {
          max-width: 126px;
        }
      }
    }
    &:nth-child(n + 49) {
      //grid-column: 1 / 4;
      grid-column: span 1;
    }
    &:nth-child(n + 50) {
      //grid-column: 1 / 4;
      //grid-column: span 1;
    }
  }

  @media ${media.tablet} {
    &:nth-child(-n + 3) {
      ${NumberBoxMediaStyled} {
        padding: 16px 32px;
        justify-content: center;
      }
      ${NumberBoxContentStyled} {
        padding: 32px;
      }
      ${NumberBoxTitleStyled} {
        font-size: 60px;
        line-height: 1;
        letter-spacing: -1px;
      }
      ${NumberBoxInfoStyled} {
        font-size: 20px;
        line-height: 1.5;
      }
      ${NumberBoxActionStyled} {
        margin-top: auto;
      }
    }
    &:nth-child(n + 4) {
      grid-column: span 1;
      ${NumberBoxMediaStyled} {
        svg {
          max-width: 180px;
          height: auto;
        }
      }
    }
    &:nth-child(1) {
      grid-area: 1 / 1 / span 2 / span 3;
      ${NumberBoxMediaStyled} {
        svg {
          max-width: 253px;
        }
      }
    }
    &:nth-child(2) {
      grid-area: 2 / 6 / span 2 / span 3;
      ${NumberBoxMediaStyled} {
        svg {
          max-width: 245px;
        }
      }
    }
    &:nth-child(3) {
      grid-area: 3 / 1 / span 2 / span 3;
      ${NumberBoxMediaStyled} {
        svg {
          max-width: 253px;
        }
      }
    }
    &:nth-child(4) {
      grid-area: 4 / 7 / auto / span 4;
      ${NumberBoxMediaStyled} {
        justify-content: flex-end;
        svg {
          max-width: 220px;
        }
      }
    }
    &:nth-child(5) {
      grid-area: 1 / 4 / span 2 / span 2;
      ${NumberBoxMediaStyled} {
        align-items: flex-end;
        svg {
          max-width: 168px;
        }
      }
    }
    &:nth-child(6) {
      grid-area: 4 / 4 / auto / span 3;
      ${NumberBoxMediaStyled} {
        justify-content: flex-end;
        svg {
          max-width: 174px;
        }
      }
    }
    &:nth-child(7) {
      grid-area: 1 / 8 / auto / span 3;
      ${NumberBoxMediaStyled} {
        justify-content: flex-end;
        svg {
          max-width: 169px;
        }
      }
    }
    &:nth-child(8) {
      grid-area: 2 / 9 / span 2 / span 2;
      ${NumberBoxMediaStyled} {
        align-items: flex-end;
        svg {
          max-width: 155px;
        }
      }
    }
    &:nth-child(9) {
      grid-area: 1 / 6 / auto / span 2;
      ${NumberBoxMediaStyled} {
        svg {
          max-width: 151px;
        }
      }
    }
    &:nth-child(10) {
      grid-area: 3 / 4 / auto / span 2;
      ${NumberBoxMediaStyled} {
        svg {
          max-width: 184px;
        }
      }
    }
    &:nth-child(n + 11) {
      grid-column: span 2;
      ${NumberBoxMediaStyled} {
        svg {
          height: 140px;
        }
      }
    }
    &:nth-child(n + 50) {
      &:after {
        display: none;
      }
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:nth-child(5) {
      ${NumberBoxMediaStyled} {
        justify-content: flex-end;
      }
    }
    &:nth-child(n + 11) {
      ${NumberBoxMediaStyled} {
        svg {
          height: auto;
        }
      }
    }
  }
`

export { BrandsGridContainerStyled, BrandsGridStyled, BrandsGridItemStyled }
