import React from "react"

import Number1 from "./../assets/images/svg/number-ico-1-alt.svg"
import Number2 from "./../assets/images/svg/number-ico-2-alt.svg"
import Number3 from "./../assets/images/svg/number-ico-3-alt.svg"
import Number4 from "./../assets/images/svg/number-ico-4-alt.svg"
import Number5 from "./../assets/images/svg/number-ico-5-alt.svg"
import Number6 from "./../assets/images/svg/number-ico-6-alt.svg"
import Number7 from "./../assets/images/svg/number-ico-7-alt.svg"
import Number8 from "./../assets/images/svg/number-ico-8-alt.svg"
import Number9 from "./../assets/images/svg/number-ico-9-alt.svg"
import Number10 from "./../assets/images/svg/number-ico-10-alt.svg"
import Number11 from "./../assets/images/svg/number-ico-11-alt.svg"
import Number12 from "./../assets/images/svg/number-ico-12-alt.svg"
import Number13 from "./../assets/images/svg/number-ico-13-alt.svg"
import Number14 from "./../assets/images/svg/number-ico-14-alt.svg"
import Number15 from "./../assets/images/svg/number-ico-15-alt.svg"
import Number16 from "./../assets/images/svg/number-ico-16-alt.svg"
import Number17 from "./../assets/images/svg/number-ico-17-alt.svg"
import Number18 from "./../assets/images/svg/number-ico-18-alt.svg"
import Number19 from "./../assets/images/svg/number-ico-19-alt.svg"
import Number20 from "./../assets/images/svg/number-ico-20-alt.svg"
import Number21 from "./../assets/images/svg/number-ico-21-alt.svg"
import Number22 from "./../assets/images/svg/number-ico-22-alt.svg"
import Number23 from "./../assets/images/svg/number-ico-23-alt.svg"
import Number24 from "./../assets/images/svg/number-ico-24-alt.svg"
import Number25 from "./../assets/images/svg/number-ico-25-alt.svg"
import Number26 from "./../assets/images/svg/number-ico-26-alt.svg"
import Number27 from "./../assets/images/svg/number-ico-27-alt.svg"
import Number28 from "./../assets/images/svg/number-ico-28-alt.svg"
import Number29 from "./../assets/images/svg/number-ico-29-alt.svg"
import Number30 from "./../assets/images/svg/number-ico-30-alt.svg"
import Number31 from "./../assets/images/svg/number-ico-31-alt.svg"
import Number32 from "./../assets/images/svg/number-ico-32-alt.svg"
import Number33 from "./../assets/images/svg/number-ico-33-alt.svg"
import Number34 from "./../assets/images/svg/number-ico-34-alt.svg"
import Number35 from "./../assets/images/svg/number-ico-35-alt.svg"
import Number36 from "./../assets/images/svg/number-ico-36-alt.svg"
import Number37 from "./../assets/images/svg/number-ico-37-alt.svg"
import Number38 from "./../assets/images/svg/number-ico-38-alt.svg"
import Number39 from "./../assets/images/svg/number-ico-39-alt.svg"
import Number40 from "./../assets/images/svg/number-ico-40-alt.svg"
import Number41 from "./../assets/images/svg/number-ico-41-alt.svg"
import Number42 from "./../assets/images/svg/number-ico-42-alt.svg"
import Number43 from "./../assets/images/svg/number-ico-43-alt.svg"
import Number44 from "./../assets/images/svg/number-ico-44-alt.svg"
import Number45 from "./../assets/images/svg/number-ico-45-alt.svg"
import Number46 from "./../assets/images/svg/number-ico-46-alt.svg"
import Number47 from "./../assets/images/svg/number-ico-47-alt.svg"
import Number48 from "./../assets/images/svg/number-ico-48-alt.svg"
import Number49 from "./../assets/images/svg/number-ico-49-alt.svg"
import Number50 from "./../assets/images/svg/number-ico-50-alt.svg"
let numberImages = []

numberImages.push(
  <Number1 />,
  <Number2 />,
  <Number3 />,
  <Number4 />,
  <Number5 />,
  <Number6 />,
  <Number7 />,
  <Number8 />,
  <Number9 />,
  <Number10 />,
  <Number11 />,
  <Number12 />,
  <Number13 />,
  <Number14 />,
  <Number15 />,
  <Number16 />,
  <Number17 />,
  <Number18 />,
  <Number19 />,
  <Number20 />,
  <Number21 />,
  <Number22 />,
  <Number23 />,
  <Number24 />,
  <Number25 />,
  <Number26 />,
  <Number27 />,
  <Number28 />,
  <Number29 />,
  <Number30 />,
  <Number31 />,
  <Number32 />,
  <Number33 />,
  <Number34 />,
  <Number35 />,
  <Number36 />,
  <Number37 />,
  <Number38 />,
  <Number39 />,
  <Number40 />,
  <Number41 />,
  <Number42 />,
  <Number43 />,
  <Number44 />,
  <Number45 />,
  <Number46 />,
  <Number47 />,
  <Number48 />,
  <Number49 />,
  <Number50 />
)

export { numberImages }
