import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import BrandsGrid from "../components/BrandsGrid"
import { numberImages as images } from "./../constants/topNumbersSvg"

const TopPage = ({ data }) => {
  return (
    <Layout withoutFooter isDarkBg>
      <SEO
        title="The top 50 of B2B’s Brightest Brands"
        description="Assessing over 200 brands, it is clear that a higher income revenue does not equate to a brighter brand. Find out which brands shine the brightest and make our top 50."
      />
      <BrandsGrid {...{ images, data }} items={data} />
    </Layout>
  )
}

export default TopPage

export const pageQuery = graphql`
  query {
    brands: allAirtable(
      filter: { table: { eq: "Brands" } }
      sort: { fields: data___Position, order: ASC }
    ) {
      nodes {
        data {
          Name
          Position
          Score
          Sector
          Slug
        }
        table
        recordId
      }
    }
  }
`

// export const pageQuery = graphql`
//   query {
//     brands: allAirtable(
//       filter: { table: { eq: "Brands" } }
//       sort: { fields: data___Position, order: ASC }
//     ) {
//       nodes {
//         table
//         recordId
//         data {
//           Name
//           Description
//           Content
//           Position
//           Score
//           Position
//           Revenue
//           Website
//           Slug
//           Logo {
//             url
//           }
//           Sector {
//             data {
//               Name
//             }
//           }
//           Listed {
//             data {
//               Name
//             }
//           }
//           Strenghts {
//             data {
//               Name
//               Content
//               Icon {
//                 data {
//                   Attachments {
//                     url
//                   }
//                 }
//               }
//             }
//           }
//           Opportunities {
//             data {
//               Name
//               Content
//               Icon {
//                 data {
//                   Attachments {
//                     url
//                   }
//                 }
//               }
//             }
//           }
//           Testimonials {
//             data {
//               Photo {
//                 url
//               }
//               NameSurname
//               JobTitle
//               Company
//               Content
//             }
//           }
//           Listed {
//             data {
//               Name
//             }
//           }
//           ChartTitle
//           ChartItems {
//             data {
//               Title
//               FirstChart
//               SecondChart
//             }
//           }
//           ChartLegend {
//             data {
//               Items
//             }
//           }
//         }
//       }
//     }
//   }
// `
